import Vue from 'vue'
import Router from 'vue-router'
import App from './App.vue'
import Home from './pages/Home.vue'
import AboutUs from './pages/AboutUs.vue'
import FAQ from './pages/FAQ.vue'
import Contact from './pages/Contact.vue'
import vTour from './pages/vTour.vue'
import Gallery from './pages/Gallery.vue'

Vue.use(Router)

Vue.config.productionTip = false

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
    },
    {
      path: '/aboutus',
      name: 'About Us',
      component: AboutUs,
    },
    {
      path: '/faq',
      name: 'Freqeuntly Asked Questions',
      component: FAQ,
    },
    {
      path: '/contact',
      name: 'Contact Us',
      component: Contact,
    },
    {
      path: '/vtour',
      name: 'Virtual Tour',
      component: vTour,
    },
    {
      path: '/gallery',
      name: 'Gallery',
      component: Gallery,
    }
  ]
})

new Vue({
  el: '#app',
  render: h => h(App),
  router
})