<template>
  <div class="container">
    <section class="section">
        <h1 class="title has-text-centered my-5">About Big Sky Preschool</h1>
    </section>
    <section class="section">
        <h1 class="title is-size-4">General</h1>
        <p>We are a nature-based preschool located in Riverside, CA. We believe that children learn best through the natural environment and strive to bring nature into many aspects of the school day. Our program serves children ages 2 to 5 years. We offer full and part time options to meet the needs of your family. We strive to maintain the highest quality standards to support the growth and development of your child.</p>
    </section>
    <section class="section">
        <h1 class="title is-size-4">Nature Based Learning</h1>
        <p>We strongly believe that children learn best when they are able to explore their natural environment. To this end, we have many outdoor based activities in our gardens and outdoor play areas. Whenever possible, activities are held outside including small group activities, music time, and (of course!) play.</p>
    </section>
    <section class="section">
        <h1 class="title is-size-4">California Preschool Program Guidelines</h1>
        <p>Big Sky Preschool has aligned its program with the California Preschool Program Guidelines. These guidelines were developed to meet the age appropriate development of preschool children and help establish programs which prepare children for Kindergarten at California schools. You can read more about the California Preschool Program Guidelines <a href="https://www.cde.ca.gov/sp/cd/re/documents/preschoolproggdlns2015.pdf">here</a>. To learn more about the benefits of incorporating nature into learning, check out <a href="https://naturalearning.org/sites/default/files/Benefits%20of%20Connecting%20Children%20with%20Nature_InfoSheet.pdf">this article.</a></p>
    </section>
    <section class="section">
        <h1 class="title is-size-4">High Scope Curriculum</h1>
        <p>While our curriculum is a fusion of many of the best practices from several philosophies, we are primarily based on the High Scope Curriculum. The High Scope Curriculum is an evidence-based preschool curriculum based on the principles of active learning. You can read more about the High Scope curriculum <a href="https://highscope.org/our-practice/preschool-curriculum/">here.</a></p>
    </section>
    <section class="section">
        <h1 class="title is-size-4">Child Assessments</h1>
        <p>Big Sky Preschool monitors the development of each of our enrolled children through the use of Desired Results Development Profiles (DRDPs). This assessment tool helps our program monitor the social, emotional, and academic development of your child. You can read more about DRDPs <a href="https://www.cde.ca.gov/sp/cd/ci/documents/drdp2015preschool.pdf">here.</a></p>
    </section>
  </div>
</template>

<script>
export default {
  name: 'aboutus',
}
</script>

<style>
</style>
