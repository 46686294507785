<template>
    <footer class="footer">
        <div class="container">
            <div class="columns">
                <div class="column">
                    <div class="my-2">
                        <h3 class="subtitle has-text-left has-text-black">
                            Call to schedule a tour <span>(951) 689-7022</span>
                        </h3>
                    </div>
                    <div class="my-2">
                        <p>Open 7:00 AM - 6:00 PM</p>
                    </div>
                    <div class="my-2">
                        <a class="button bd-fat-button is-primary" target="_blank" href="https://www.facebook.com/bigskypreschool">
                            <span class="icon">
                                <i class="fab fa-facebook-square"></i>
                            </span>
                            <span>Our Facebook</span>
                        </a>
                    </div>
                </div>
                <div class="column">
                    <div class="my-2">
                        <h3 class="subtitle has-text-right has-text-black">
                            10744 Hole Ave, Riverside, CA 92505
                        </h3>
                    </div>
                    <div class="my-2">
                        <p class="has-text-right">{{copyright}}</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
  name: "navbar",
  props: {
      
  },
  data() {
    return {
        copyright: "© 2019 - " + new Date().getFullYear(),

    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
