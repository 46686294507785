<template>
  <div class="container">
    <div>
      <section class="section">
        <h1 class="title has-text-centered">Virtual Tour</h1>
        <div class="box">
          <video controls id="video">
            <source src="../assets/videoTour.mp4" type="video/mp4">
            <source src="https://player.vimeo.com/video/510387900" type="video/mp4">
            Your Browser Does not support video
          </video>
        </div>
      </section>
      <section class="section" id="tour">
        <h1 class="title has-text-centered">3D View</h1>
        <div class="box">
          <iframe src="https://my.matterport.com/show/?m=hCmotqjkeqR" frameborder="0"></iframe>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "vTour",
}
</script>

<style scoped>
iframe {
  width: 100%;
  min-height: 40vw;
}
</style>