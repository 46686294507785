<template>
  <div class="container">
    <section class="section">
      <h1 class="title">Our Philosophy</h1>
      <h2 class="subtitle">Learning through nature</h2>
      <p>We believe that children learn best from the natural environment. Our established gardens and play areas, along with highly-skilled and educated teachers, allow children to learn through exploration of their natural environment. At Big Sky Preschool, we know that learning happens both inside and outside the classroom. Our teachers use the outdoor play areas filled with musical instruments, building materials, gardens, butterflies, and soaring trees to enhance the preschool learning experience.</p>
    </section>
    <section class="section">
      <div class="columns">
        <div class="column"></div>
        <div class="column">
          <figure class="image">
            <img src="../assets/gallery/Picture9.jpg" alt="Children Playing">
          </figure>
        </div>
        <div class="column">
          <figure class="image">
            <img src="../assets/gallery/Picture10.jpg" alt="Garden Arc">
          </figure>
        </div>
        <div class="column">
          <figure class="image">
            <img src="../assets/gallery/IMG_20170616_122529.jpg" alt="Lady Bug Crossing">
          </figure>
        </div>
        <div class="column"></div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'home',
}
</script>

<style>
</style>
