<template>
  <div class="container">
    <section class="section my-5">
      <div class="columns is-centered is-vcentered">
        <div class="column is-third">
          <h1 class="title is-size-4 has-text-centered">Where We Are</h1>
        </div>
        <div class="column is-two-thirds">
          <div class="">
            <iframe
              class="box"
              id="gmap_canvas"
              src="https://maps.google.com/maps?q=10744%20Hole%20Ave%2C%20Riverside&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
    <section class="section my-5">
      <div class="columns is-centered is-vcentered">
        <h1 class="title">To Contact Us, please send us an email at info@bigskypreschool.com</h1>
        <!--
        <div class="column is-third">
          <h1 class="title is-size-4 has-text-centered">Contact Form</h1>
        </div>
        <div class="column is-two-thirds box has-background-primary">
          <form action="" class="p-5 mx-5">
            <div class="columns is-centered">
                <div class="column is-two-thirds">
                    <div class="field">
                        <label for="" class="label">Name</label>
                        <div class="control">
                            <input type="text" class="input" placeholder="e.g John Doe" />
                        </div>
                    </div>
                    <div class="field">
                        <label for="" class="label">Email</label>
                        <div class="control">
                            <input type="email" class="input" placeholder="person@gmail.com" />
                        </div>
                    </div>
                    <div class="field">
                        <label for="" class="label">Phone Number (Numbers only)</label>
                        <div class="control">
                            <input type="tel" class="input" placeholder="(000) 000 000" />
                        </div>
                    </div>
                    <div class="field">
                        <label for="" class="label">Message</label>
                        <div class="control">
                            <textarea name="message" class="input" placeholder="Questions, Concerns, etc.s" required=""></textarea>
                        </div>
                    </div>
                    <div class="field">
                        <div class="control has-text-centered">
                            <input type="submit" name="submit" id="submit" class="button is-link" value="Send Message">
                        </div>
                    </div>
                </div>
            </div>
          </form>
        </div>
        -->
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Contact",
};
</script>

<style scoped>
#gmap_canvas {
  min-width: 100%;
  max-width: 100%;
  min-height: calc(25vw + 100px);
}

label {
  color: white;
}

textarea {
    width: 100% !important;
    height: 100px;
}
</style>
