<template>
  <div class="container section">
    <div class="columns">
      <div class="column has-text-centered">
        <router-link class="button bd-fat-button is-primary" to="/contact">
          <span class="icon">
            <i class="fas fa-envelope-open-text"></i>
          </span>
          <span>Contact</span>
        </router-link>
      </div>
      <div class="column has-text-centered">
        <router-link class="button bd-fat-button is-link" to="/vtour">
          <span class="icon">
            <i class="fas fa-binoculars"></i>
          </span>
          <span>Virtual & 3D Tour</span>
        </router-link>
      </div>
      <div class="column has-text-centered">
        <p class="button bd-fat-button is-primary" id="phone">
          <span class="icon">
            <i class="fas fa-phone"></i>
          </span>
          <span>(951) 689-7022</span>
        </p>
      </div>
      <div class="column has-text-centered">
        <a
          class="button bd-fat-button is-link"
          href="https://www.facebook.com/bigskypreschool" target="_blank">
          <span class="icon">
            <i class="fab fa-facebook-square"></i>
          </span>
          <span>Facebook</span>
        </a>
      </div>
      <div class="column has-text-centered">
        <a class="button bd-fat-button is-primary" href="https://www.instagram.com/bigskypreschool/" target="_blank">
          <span class="icon">
            <i class="fab fa-instagram"></i>
          </span>
          <span>Instagram</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "hero",
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
