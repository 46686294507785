<template>
  <div id="app">
    <navbar />
    <hero />
    <cta />
    <router-view class="my-5"></router-view>
    <foot />
  </div>
</template>

<script>
require('./main.scss');

import navbar from './components/navbar.vue'
import foot from './components/footer.vue'
import hero from './components/hero.vue'
import cta from './components/CTA.vue'

export default {
  name: 'App',
  components: {
    navbar,
    hero,
    foot,
    cta,
  },
}
</script>

<style>
</style>
