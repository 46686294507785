<template>
  <nav class="navbar box p-0 m-0" role="navigation" aria-label="main navigation">
    <div class="container">
      <div class="navbar-brand">
        <a
          role="button"
          class="navbar-burger"
          aria-label="menu"
          id="burger"
          aria-expanded="false"
          data-target="navbar"
          v-on:click="toggleBurger()"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navbar" class="navbar-menu">
        <p class="navbar-item has-text-primary has-text-weight-bold">Big Sky Preschool</p>
        <router-link class="navbar-item" to="/">Home</router-link>
        <router-link class="navbar-item" to="/vtour">Virtual Tour</router-link>
        <router-link class="navbar-item" to="/aboutus">About Us</router-link>
        <router-link class="navbar-item" to="/gallery">Gallery</router-link>
        <router-link class="navbar-item" to="/faq">FAQ</router-link>

        <div class="navbar-end">
          <router-link class="navbar-item is-primary" to="/contact">Contact</router-link>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "navbar",
  props: {},
  methods: {
    toggleBurger: function () {
      document.getElementById("burger").classList.toggle("is-active");
      document.getElementById("navbar").classList.toggle("is-active");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.navbar {
  border-radius: 0;
}
</style>
