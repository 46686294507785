<template>
  <div class="container">
    <section class="section">
        <h1 class="title has-text-centered my-5">Frequently Asked Questions</h1>
    </section>
    <section class="section">
        <h1 class="title is-size-4">What Ratios Does Big Sky Preschool Maintain?</h1>
        <p>We maintain a 10:1 ratio, which is below the state's licensing standards. We believe this gives our teachers the opportunity to give each child extra attention and care.</p>
    </section>
    <section class="section">
        <h1 class="title is-size-4">What Hours Is Child Care Provided?</h1>
        <p>For children in the full day program, the center is open from 7:00 AM through 6:00 PM. For children enrolled in our half day program, the hours are 8:30 to 12:00 for the morning program or 2:00 PM to 5:30 PM for the afternoon program.</p>
    </section>
    <section class="section">
        <h1 class="title is-size-4">Do You Provide Child Care (Day Care) In Addition To Your Preschool Program?</h1>
        <p>Yes, we provide full day child care (day care) options for parents needing full time care.</p>
    </section>
    <section class="section">
        <h1 class="title is-size-4">What Schedules Are Available?</h1>
        <p>We offer five day, Monday/Wednesday/Friday, and Tuesday/Thursday options.</p>
    </section>
    <section class="section">
        <h1 class="title is-size-4">What Meals Are Included?</h1>
        <p>We provide a morning snack, lunch, and an afternoon snack.</p>
    </section>
    <section class="section">
        <h1 class="title is-size-4">Does Big Sky Preschool Provide Care For Non-Potty Trained Children?</h1>
        <p>Yes, our teachers work closely with parents to determine when a child is ready to start potty training and coordinates with parents' efforts at home.</p>
    </section>
    <section class="section">
        <h1 class="title is-size-4">What payment options are available?</h1>
        <p>Big Sky Preschool will automatically process payments through recurring credit card or direct withdrawals from your checking account (ACH) transactions. We ask every parent to register for automatic payments upon enrollment.</p>
    </section>
    <section class="section">
        <h1 class="title is-size-4">Do you offer free or subsidized childcare?</h1>
        <p>We accept subsidy payments from various state and local agencies. Please call us at 951-689-7022 or email us at info@bigskypreschool.com if you think you might qualify for subsidized care and we can talk to you about who to contact in order to qualify.</p>
    </section>
  </div>
</template>

<script>
export default {
  name: 'faq',
}
</script>

<style>
</style>
