<template>
  <div class="container">
    <section class="section">
      <h1 class="title has-text-centered">Gallery</h1>
    </section>
    <section class="section">
      <div class="columns is-multiline is-1">
        <div class="column"><figure class="image">
          <img src='../assets/gallery/IMG_5902.jpg'>
        </figure></div>
        <div class="column"><figure class="image">
          <img src='../assets/gallery/IMG_5910.jpg'>
        </figure></div>
        <div class="column"><figure class="image">
          <img src='../assets/gallery/IMG_5892.jpg'>
        </figure></div>
        <div class="column"><figure class="image">
          <img src='../assets/gallery/IMG_5891.jpg'>
        </figure></div>
        <div class="column"><figure class="image">
          <img src='../assets/gallery/IMG_5908.jpg'>
        </figure></div>
        <div class="column"><figure class="image">
          <img src='../assets/gallery/IMG_5901.jpg'>
        </figure></div>
        <div class="column"><figure class="image">
          <img src='../assets/gallery/IMG_5888.jpg'>
        </figure></div>
        <div class="column"><figure class="image">
          <img src='../assets/gallery/Picture10.jpg'>
        </figure></div>
        <div class="column"><figure class="image">
          <img src='../assets/gallery/IMG_5915.jpg'>
        </figure></div>
        <div class="column"><figure class="image">
          <img src='../assets/gallery/Picture6.jpg'>
        </figure></div>
        <div class="column"><figure class="image">
          <img src='../assets/gallery/IMG_5895.jpg'>
        </figure></div>
        <div class="column"><figure class="image">
          <img src='../assets/gallery/IMG_5883.jpg'>
        </figure></div>
        <div class="column"><figure class="image">
          <img src='../assets/gallery/Picture7.jpg'>
        </figure></div>
        <div class="column"><figure class="image">
          <img src='../assets/gallery/IMG_5918.jpg'>
        </figure></div>
        <div class="column"><figure class="image">
          <img src='../assets/gallery/IMG_5914.jpg'>
        </figure></div>
        <div class="column"><figure class="image">
          <img src='../assets/gallery/Picture8.jpg'>
        </figure></div>
        <div class="column"><figure class="image">
          <img src='../assets/gallery/Picture11.jpg'>
        </figure></div>
        <div class="column"><figure class="image">
          <img src='../assets/gallery/IMG_5899.jpg'>
        </figure></div>
        <div class="column"><figure class="image">
          <img src='../assets/gallery/IMG_5907.jpg'>
        </figure></div>
        <div class="column"><figure class="image">
          <img src='../assets/gallery/IMG_20170616_122529.jpg'>
        </figure></div>
        <div class="column"><figure class="image">
          <img src='../assets/gallery/IMG_5919.jpg'>
        </figure></div>
        <div class="column"><figure class="image">
          <img src='../assets/gallery/Picture9.jpg'>
        </figure></div>
        <div class="column"><figure class="image">
          <img src='../assets/gallery/IMG_5897.jpg'>
        </figure></div>
        <div class="column"><figure class="image">
          <img src='../assets/gallery/IMG_5916.jpg'>
        </figure></div>
        <div class="column"><figure class="image">
          <img src='../assets/gallery/IMG_5905.jpg'>
        </figure></div>
        <div class="column"><figure class="image">
          <img src='../assets/gallery/IMG_5898.jpg'>
        </figure></div>
        <div class="column"><figure class="image">
          <img src='../assets/gallery/Picture5.jpg'>
        </figure></div>
        <div class="column"><figure class="image">
          <img src='../assets/gallery/IMG_5889.jpg'>
        </figure></div>
        <div class="column"><figure class="image">
          <img src='../assets/gallery/Picture4.jpg'>
        </figure></div>
        <div class="column"><figure class="image">
          <img src='../assets/gallery/IMG_20170616_122042.jpg'>
        </figure></div>
        <div class="column"><figure class="image">
          <img src='../assets/gallery/IMG_5890.jpg'>
        </figure></div>
        <div class="column"><figure class="image">
          <img src='../assets/gallery/IMG_5894.jpg'>
        </figure></div>
        <div class="column"><figure class="image">
          <img src='../assets/gallery/IMG_5887.jpg'>
        </figure></div>
        <div class="column"><figure class="image">
          <img src='../assets/gallery/IMG_5917.jpg'>
        </figure></div>
        <div class="column"><figure class="image">
          <img src='../assets/gallery/IMG_5885.jpg'>
        </figure></div>
        <div class="column"><figure class="image">
          <img src='../assets/gallery/IMG_5904.jpg'>
        </figure></div>
        <div class="column"><figure class="image">
          <img src='../assets/gallery/IMG_5900.jpg'>
        </figure></div>
        <div class="column"><figure class="image">
          <img src='../assets/gallery/IMG_5896.jpg'>
        </figure></div>
        <div class="column"><figure class="image">
          <img src='../assets/gallery/IMG_5913.jpg'>
        </figure></div>
        <div class="column"><figure class="image">
          <img src='../assets/gallery/IMG_5886.jpg'>
        </figure></div>
        <div class="column"><figure class="image">
          <img src='../assets/gallery/IMG_5903.jpg'>
        </figure></div>
        <div class="column"><figure class="image">
          <img src='../assets/gallery/IMG_5906.jpg'>
        </figure></div>
        <div class="column"><figure class="image">
          <img src='../assets/gallery/IMG_5909.jpg'>
        </figure></div>
        <div class="column"><figure class="image">
          <img src='../assets/gallery/IMG_5911.jpg'>
        </figure></div>
        <div class="column"><figure class="image">
          <img src='../assets/gallery/IMG_5893.jpg'>
        </figure></div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "gallery",
}
</script>

<style scoped>
.column {
  min-width: 250px;
  max-width: 400px;
}
</style>