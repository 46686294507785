<template>
  <div class="columns is-gapless box p-0 m-0">
    <div class="column is-one-third">
      <div class="hero is-large has-background-primary">
        <div class="container">
          <div class="hero-body">
            <h1 class="title has-text-white has-text-left">
              Big Sky Preschool
            </h1>
            <h2 class="subtitle has-text-white">{{currentRouteName}}</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="hero is-large is-primary" id="hero">
        <div class="hero-body"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "hero",
  props: {},
  computed: {
    currentRouteName() {
        return this.$route.name;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#hero {
  background-image: url("../assets/playground.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-clip: content-box;
  height: 100%;
}
</style>
